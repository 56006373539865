import { PROTECTED_PATHS, URL_PATHS } from "@/constants/navigation";
import { useReadSharesHasRole } from "@/wagmi.generated";
import { useCallback, useEffect, useState } from "react";
import { keccak256, toBytes, zeroAddress } from "viem";
import { useAccount, useConfig } from "wagmi";
import { usePathname, useRouter } from "next/navigation";
import { useConfigContext } from "@/context/configContext";
import { SiweStatus, useSiwe } from "./useSiwe";

const INVESTOR_ROLE = keccak256(toBytes("INVESTOR"));

export default function useAuthorization() {
  const { chains: expectedChains } = useConfig();
  const { fund } = useConfigContext();
  const pathname = usePathname();
  const { siweStatus, siwe } = useSiwe();
  const router = useRouter();

  const { chain, address: accountAddress, isConnected } = useAccount();
  const [isAccessDeniedModalOpen, setIsAccessDeniedModalOpen] = useState(false);
  const address = accountAddress ?? zeroAddress;

  const { data: isUserInvestor, isFetched: hasFetchedRole } =
    useReadSharesHasRole({
      address: fund.shares.address,
      args: [INVESTOR_ROLE, address],
    });

  const isAuthorizationReady =
    siweStatus !== SiweStatus.Initializing && hasFetchedRole;

  const isAuthenticatedInvestor =
    siweStatus === SiweStatus.Authenticated && !!isUserInvestor;

  const isOnCorrectNetwork = chain?.id === expectedChains[0].id;

  const isLoggedIn = isAuthenticatedInvestor && isOnCorrectNetwork;

  const shouldShowAccessDenied = useCallback(() => {
    const isProtectedPath = PROTECTED_PATHS.includes(pathname);
    return (
      isAuthorizationReady &&
      (!isAuthenticatedInvestor || !isOnCorrectNetwork) &&
      isProtectedPath
    );
  }, [
    pathname,
    isAuthorizationReady,
    isAuthenticatedInvestor,
    isOnCorrectNetwork,
  ]);

  useEffect(() => {
    if (isLoggedIn && isAccessDeniedModalOpen) {
      setIsAccessDeniedModalOpen(false);
    }
  }, [isAccessDeniedModalOpen, isLoggedIn]);

  useEffect(() => {
    if (shouldShowAccessDenied()) {
      setIsAccessDeniedModalOpen(true);
      router.replace(URL_PATHS.REPORT);
    }
  }, [router, shouldShowAccessDenied]);

  return {
    isLoggedIn,
    isOnCorrectNetwork,
    isAuthorizationReady,
    isUserInvestor: isUserInvestor || false,
    isConnected,
    address,
    chain: chain?.id,
    siweStatus,
    siwe: () => siwe({ address, chainId: chain?.id }),
    isAccessDeniedModalOpen,
    showAccessDeniedModal: () => setIsAccessDeniedModalOpen(true),
    closeAccessDeniedModal: () => setIsAccessDeniedModalOpen(false),
  };
}
