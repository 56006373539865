import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { defineChain } from "viem";
import * as viemChains from "viem/chains";
import type { RpcConfig } from "./data/interfaces/config";

const projectId = process.env.NEXT_PUBLIC_WALLETCONNECT_ID!;

if (!projectId) {
  throw "NEXT_PUBLIC_WALLETCONNECT_ID not set";
}

const chains = (rpcConfig: RpcConfig) =>
  Object.keys(rpcConfig).map((chainName) => {
    const { rpc, ...other } = rpcConfig[chainName];

    const chain =
      viemChains[chainName as keyof typeof viemChains] ?? viemChains.mainnet;

    return defineChain({
      ...chain,
      ...other,
      name: chainName,
      ...(rpc
        ? {
            rpcUrls: {
              default: {
                http: [rpc],
                webSocket: [rpc.replace(/https?/, "ws")],
              },
            },
          }
        : {}),
    });
  });

export const config = (rpcConfig: RpcConfig) =>
  getDefaultConfig({
    chains: chains(rpcConfig) as any,
    appName: "Karpatkey",
    projectId,
    ssr: true,
  });
