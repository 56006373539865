"use client";

import { useEffect, useState } from "react";
import { watchAccount } from "@wagmi/core";
import { getIsAuthenticated, logout } from "@/data/api/auth";

import { useSiweMutation } from "./useSiweMutation";
import { useConfigContext } from "@/context/configContext";

export enum SiweStatus {
  Initializing = "initializing",
  Authenticated = "authenticated",
  Unauthenticated = "unauthenticated",
  Loading = "loading",
}

export const useSiwe = () => {
  const { chainConfig } = useConfigContext();

  const [siweStatus, setSiweStatus] = useState<SiweStatus>(
    SiweStatus.Initializing,
  );

  const { mutate: siwe } = useSiweMutation(setSiweStatus, chainConfig);

  useEffect(() => {
    async function checkAuthentication() {
      try {
        await getIsAuthenticated();
        setSiweStatus(SiweStatus.Authenticated);
      } catch {
        setSiweStatus(SiweStatus.Unauthenticated);
        await logout();
      }
    }

    checkAuthentication();
  }, []);

  useEffect(() => {
    const unwatch = watchAccount(chainConfig, {
      async onChange(account, prevAccount) {
        if (
          (account.address && siweStatus === SiweStatus.Unauthenticated) ||
          (account.status === "connected" &&
            account.address &&
            account.address !== prevAccount.address &&
            siweStatus !== SiweStatus.Loading)
        ) {
          siwe({ address: account.address, chainId: account.chainId });
        } else if (
          prevAccount.status === "connected" &&
          account.status === "disconnected"
        ) {
          await logout();
          setSiweStatus(SiweStatus.Unauthenticated);
        }
      },
    });

    return () => unwatch();
  }, [siwe, siweStatus, chainConfig]);

  return { siweStatus, siwe };
};
