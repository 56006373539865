import axios from "axios";

const getApiUrl = () => {
  if (typeof window === "undefined") return "";

  const customApiUrl = localStorage.getItem("api_url");
  if (customApiUrl) return customApiUrl;

  if (process.env.NEXT_PUBLIC_API_URL) return process.env.NEXT_PUBLIC_API_URL;

  const { hostname, protocol } = location;

  let apiHostname = hostname;

  if (hostname.startsWith('app.'))
    apiHostname = hostname.replace("app", "api");
  else
    apiHostname = `api.${hostname}`;

  return `${protocol}//${apiHostname}`;
};

const API_URL = getApiUrl();

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      const { status } = error.response;
      console.error("Response error status:", status);
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
