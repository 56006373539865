"use client";

import Link from "next/link";
import classNames from "classnames";
import InteractiveDot from "./Icons/InteractiveCircle";
import InteractiveDotSubtab from "./Icons/InteractiveCircleSubtab";
import { usePathname } from "next/navigation";
import { URL_PATHS } from "@/constants/navigation";
import { useAuthorizationContext } from "@/context/authorizationContext";

interface TabProps {
  children: React.ReactNode;
  currentPath: string;
  tabPath: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

interface SubtabProps {
  children: React.ReactNode;
  sectionId: string;
  disabled?: boolean;
}

export default function Sidebar() {
  const pathname = usePathname();
  const { isLoggedIn, showAccessDeniedModal, isAuthorizationReady } =
    useAuthorizationContext();

  const handleTransactionsClick = (e: React.MouseEvent) => {
    if (!isLoggedIn) {
      e.preventDefault();
      showAccessDeniedModal();
    }
  };

  return (
    <nav className="fixed w-64 h-full border-r border-gray-400 z-30 mt-28 py-5 pl-10 pr-5 bg-gray-100">
      <Tab currentPath={pathname} tabPath={URL_PATHS.REPORT}>
        Report
      </Tab>

      <div className="ml-4 mt-[-14px]">
        <Subtab sectionId="fund-properties">Fund properties</Subtab>
        <Subtab sectionId="fund-performance">Fund performance</Subtab>
        <Subtab sectionId="portfolio">Portfolio</Subtab>
        {isLoggedIn && <Subtab sectionId="user-position">My position</Subtab>}
      </div>

      <Tab
        currentPath={pathname}
        tabPath={URL_PATHS.TRANSACTIONS}
        onClick={handleTransactionsClick}
        disabled={!isAuthorizationReady}
      >
        Transactions
      </Tab>
    </nav>
  );
}

function Tab({
  currentPath,
  tabPath,
  children,
  onClick,
  disabled = false,
}: TabProps) {
  const active = currentPath === tabPath;

  return (
    <Link
      href={tabPath}
      onClick={(e) => (disabled ? e.preventDefault() : onClick?.(e))}
    >
      <div className="py-5 flex flex-row items-center gap-2 group">
        <InteractiveDot active={active} />
        <span
          className={classNames(
            "font-mono font-bold text-xl",
            active
              ? "text-gray-900"
              : "text-gray-600 group-hover:text-gray-900",
          )}
        >
          {children}
        </span>
      </div>
    </Link>
  );
}

function Subtab({ sectionId, children }: SubtabProps) {
  const pathname = usePathname();
  const active = pathname === URL_PATHS.REPORT;
  const fullPath = `${URL_PATHS.REPORT}#${sectionId}`;
  return (
    <Link href={fullPath} scroll={true}>
      <div className="py-2 flex flex-row items-center gap-1 group">
        <InteractiveDotSubtab active={active} />
        <span
          className={classNames(
            "font-mono font-bold text-xl pl-2",
            active
              ? "text-gray-900"
              : "text-gray-600 group-hover:text-gray-900",
          )}
        >
          {children}
        </span>
      </div>
    </Link>
  );
}
